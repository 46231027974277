import adapter from "@o-din/src/adapter";

export default {
  parking: {
    issues: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/issues.json", { params: params })
      },
      archive: function(params = {}) {
        return adapter.get("api/v4/parking/issues/archive.json", { params: params })
      },
      create: function(params = {}) {
        return adapter.post("api/v4/parking/issues.json", { issue: params })
      },
      update: function(id, params = {}) {
        return adapter.patch(`api/v4/parking/issues/${id}.json`, { issue: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/issues/${id}.json`)
      }
    },
    categories: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/categories.json", { params: params })
      },
      create: function(params) {
        return adapter.post("api/v4/parking/categories.json", params )
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/parking/categories/${id}.json`, params )
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/categories/${id}.json`)
      }
    },
    vehicle_types: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/vehicle_types.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/vehicle_types/${id}.json`)
      },
      create: function(params) {
        return adapter.post("api/v4/parking/vehicle_types.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/parking/vehicle_types/${id}.json`, params)
      }
    },
    vehicles: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/vehicles.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/vehicles/${id}.json`)
      },
      create: function(params) {
        return adapter.post("api/v4/parking/vehicles.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/parking/vehicles/${id}.json`, params)
      },
    },
    passes: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/passes.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/passes/${id}.json`)
      },
      create: function(params) {
        return adapter.post("api/v4/parking/passes.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/parking/passes/${id}.json`, params)
      },
    },
    parking_slots: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/parking_slots.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/parking/parking_slots/${id}.json`)
      },
      create: function(params) {
        return adapter.post("api/v4/parking/parking_slots.json", params)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/parking/parking_slots/${id}.json`, params)
      },
    },
    simple_sessions: {
      index: function(params = {}) {
        return adapter.get("api/v4/parking/simple_sessions.json", { params: params })
      },
      create: function(params) {
        return adapter.post("api/v4/parking/simple_sessions.json", params )
      },
      update: function(params = {}) {
        return adapter.patch("api/v4/parking/simple_sessions.json", params )
      },
      export: function(params) {
        return adapter.get("api/v4/parking/simple_sessions.xlsx", { params: params, responseType: "blob" })
      }
    }
  }
}